var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-navigation-drawer',{staticClass:"menu rounded",style:({
    height:
      _vm.$store.state.break_point <= 991 ? '100%!important' : 'auto!important',
  }),attrs:{"floating":"","right":"","mini-variant":_vm.$store.state.mini,"app":_vm.$store.state.break_point <= 991},on:{"update:miniVariant":function($event){return _vm.$set(_vm.$store.state, "mini", $event)},"update:mini-variant":function($event){return _vm.$set(_vm.$store.state, "mini", $event)}},model:{value:(_vm.$store.state.drawer),callback:function ($$v) {_vm.$set(_vm.$store.state, "drawer", $$v)},expression:"$store.state.drawer"}},[_c('v-list-item',{staticClass:"px-2"},[_c('v-list-item-avatar',[_c('v-img',{attrs:{"src":"https://randomuser.me/api/portraits/men/85.jpg"}})],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$store.state.restline ? _vm.$store.state.restline.name : "Super Admin")+" ")]),(_vm.$store.state.break_point > 991)?_c('v-btn',{attrs:{"color":"grey lighten-3","fab":"","x-small":"","depressed":""},on:{"click":function($event){$event.stopPropagation();_vm.$store.state.mini = !_vm.$store.state.mini}}},[_c('v-icon',[_vm._v("chevron_right")])],1):_vm._e()],1),_c('v-divider'),(
      _vm.$store.state.translations.data.length != 0 ||
      !_vm.$store.state.restline.is_store
    )?_c('div',[_c('v-list',{attrs:{"dense":""}},[_vm._l((_vm.links),function(item,x){return _c('v-list-item',{key:x,attrs:{"active-class":"primary white--text","link":"","to":{ name: item.link }}},[_c('v-list-item-icon',{staticClass:"me-2"},[_c('v-icon',{staticStyle:{"font-size":"18px"}},[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.title))])],1)],1)}),_c('v-list-item',{attrs:{"active-class":"primary white--text"},on:{"click":function($event){return _vm.logout()}}},[_c('v-list-item-icon',{staticClass:"me-2"},[_c('v-icon',{staticStyle:{"font-size":"18px"}},[_vm._v("logout")])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.trans("menu", "menu_logout"))+" ")])],1)],1)],2)],1):_c('div',_vm._l((11),function(i){return _c('v-skeleton-loader',{key:i,attrs:{"type":"table-heading"}})}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }