import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index";
import AppAside from "@/layout/AppAside.vue";

Vue.config.productionTip = false;
Vue.use(VueRouter);
Vue.component("AppAside", AppAside);

const notfound = () => import("@/views/not_found.vue");
const login = () => import("@/views/Login.vue");
//---Start Company

const orders = () => import("@/views/orders/orders");
const pointsReport = () => import("@/views/orders/pointsReport");

const rates = () => import("@/views/orders/rates");
const locations = () => import("@/views/orders/locations");
const intro_pages = () => import("@/views/Intro_pages");
const groups = () => import("@/views/groups");
const categories = () => import("@/views/categories");
const banners = () => import("@/views/banners");
const notes = () => import("@/views/notes");

const additions = () => import("@/views/additions/additions");
const additionsTable = () => import("@/views/additions/additionsTable");
const addition = () => import("@/views/additions/addition");

const products = () => import("@/views/products/products");
const productsTable = () => import("@/views/products/productsTable");
const product = () => import("@/views/products/product");

const customers = () => import("@/views/customers/customers");
const customersTable = () => import("@/views/customers/customersTable");
const customer = () => import("@/views/customers/customer");

const employees = () => import("@/views/employees");
const delivery = () => import("@/views/deliveries");
const messages = () => import("@/views/messages.vue");
const dashboard = () => import("@/views/dashboard.vue");
const translations = () => import("@/views/translations.vue");

const settings = () => import("@/views/settings/settings.vue");
const settingsNav = () => import("@/views/settings/settingsNav.vue");
const settingsGeneral = () => import("@/views/settings/general.vue");
const notifications = () => import("@/views/settings/notifications.vue");
const invoices = () => import("@/views/settings/invoices.vue");
const branches = () => import("@/views/settings/branches");
const branch = () => import("@/views/settings/branch");
const cashers = () => import("@/views/settings/cashers");
const storeStyle = () => import("@/views/settings/storeStyle");
const payments_settings = () => import("@/views/settings/payments.vue");
const advanced_settings = () => import("@/views/settings/advanced.vue");
const mboileAppSettings = () =>
  import("@/views/settings/mboileAppSettings.vue");

//---Start Super_admin
const stores = () => import("@/views/super_admin/stores");
const deliveries = () => import("@/views/super_admin/deliveries");
const languages = () => import("@/views/super_admin/languages");
const packages = () => import("@/views/super_admin/packages");
const styleOptions = () => import("@/views/super_admin/styleOptions");

//---End Super admin

const routes = [
  {
    path: "/translations",
    name: "translations",
    component: translations,
    meta: {
      title: "translations",
      requiresAuth: true,
      super_admin: true,
    },
  },

  {
    path: "/stores",
    name: "stores",
    component: stores,
    meta: {
      title: "stores",
      requiresAuth: true,
      super_admin: true,
    },
  },
  {
    path: "/deliveries",
    name: "deliveries",
    component: deliveries,
    meta: {
      title: "deliveries",
      requiresAuth: true,
      super_admin: true,
    },
  },
  {
    path: "/languages",
    name: "languages",
    component: languages,
    meta: {
      title: "languages",
      requiresAuth: true,
      super_admin: true,
    },
  },
  {
    path: "/packages",
    name: "packages",
    component: packages,
    meta: {
      title: "packages",
      requiresAuth: true,
      super_admin: true,
    },
  },
  {
    path: "/styleOptions",
    name: "styleOptions",
    component: styleOptions,
    meta: {
      title: "styleOptions",
      requiresAuth: true,
      super_admin: true,
    },
  },
  

  //////////////------------- End Super_admin

  {
    path: "/dashboard",
    name: "dashboard",
    component: dashboard,
    meta: {
      permission: "more dashboard",
      title: "dashboard",
      requiresAuth: true,
      super_admin: false,
    },
  },

  {
    path: "/orders",
    name: "orders",
    component: orders,
    meta: {
      permission: "orders access",
      title: "orders",
      requiresAuth: true,
      super_admin: false,
    },
  },
  {
    path: "/pointsReport",
    name: "pointsReport",
    component: pointsReport,
    meta: {
      permission: "orders access",
      title: "orders",
      requiresAuth: true,
      super_admin: false,
    },
  },

  
  {
    path: "/rates",
    name: "rates",
    component: rates,
    meta: {
      permission: "more rates",
      title: "rates",
      requiresAuth: true,
      super_admin: false,
    },
  },
  {
    path: "/messages",
    name: "messages",
    component: messages,
    meta: {
      permission: "more notify topic",
      title: "messages",
      requiresAuth: true,
      super_admin: false,
    },
  },
  {
    path: "/locations",
    name: "locations",
    component: locations,
    meta: {
      permission: "locations access",
      title: "locations",
      requiresAuth: true,
      super_admin: false,
    },
  },
  {
    path: "/categories",
    name: "categories",
    component: categories,
    meta: {
      permission: "categories access",
      title: "categories",
      requiresAuth: true,
      super_admin: false,
    },
  },
  {
    path: "/banners",
    name: "banners",
    component: banners,
    meta: {
      permission: "banners access",
      title: "banners",
      requiresAuth: true,
      super_admin: false,
    },
  },

  {
    path: "/notes",
    name: "notes",
    component: notes,
    meta: {
      permission: "notes access",
      title: "notes",
      requiresAuth: true,
      super_admin: false,
    },
  },

  {
    path: "/additions",
    component: additions,
    meta: {
      permission: "additions access",
      title: "additions",
      requiresAuth: true,
      super_admin: false,
    },
    children: [
      {
        path: "/",
        name: "additions",
        component: additionsTable,
        meta: {
          permission: "additions access",
          title: "additions",
        },
      },
      {
        path: "addition/:id?",
        name: "addition",
        component: addition,
        meta: {
          permission: "additions access",
        },
      },
    ],
  },

  {
    path: "/customers",
    component: customers,
    meta: {
      title: "customers",
      requiresAuth: true,
      super_admin: false,
      permission: "customers access",
    },
    children: [
      {
        path: "/",
        name: "customers",
        component: customersTable,
        meta: {
          title: "customer",
          permission: "customers access",
        },
      },
      {
        path: ":id?",
        name: "customer",
        component: customer,
        meta: {
          title: "customer",
          permission: "customers edit",
        },
      },
    ],
  },

  {
    path: "/employees",
    name: "employees",
    component: employees,
    meta: {
      title: "employees",
      requiresAuth: true,
      super_admin: false,
      permission: "employees access",
    },
  },

  {
    path: "/intro_pages",
    name: "intro_pages",
    component: intro_pages,
    meta: {
      title: "intro_pages",
      requiresAuth: true,
      super_admin: false,
      permission: "intro_pages access",
    },
  },
  {
    path: "/groups",
    name: "groups",
    component: groups,
    meta: {
      title: "groups",
      requiresAuth: true,
      super_admin: false,
      permission: "groups access",
    },
  },
  {
    path: "/drivers",
    name: "drivers",
    component: delivery,
    meta: {
      title: "delivery",
      requiresAuth: true,
      super_admin: false,
      permission: "deliveries access",
    },
  },
  {
    path: "/settings",
    component: settings,
    meta: {
      title: "settings",
      requiresAuth: true,
      super_admin: false,
      permission: "settings access",
    },
    children: [
      {
        path: "/",
        name: "settings",
        component: settingsNav,
        meta: {
          title: "settingsNav",
          permission: "settings access",
        },
      },
      {
        path: "general",
        name: "settingsGeneral",
        component: settingsGeneral,
        meta: {
          title: "general",
          permission: "settings general",
        },
      },
      {
        path: "notifications",
        name: "notifications",
        component: notifications,
        meta: {
          title: "notifications",
          permission: "settings notifications",
        },
      },
      {
        path: "invoices",
        name: "invoices",
        component: invoices,
        meta: {
          title: "invoices",
          permission: "settings invoices",
        },
      },
      {
        path: "payments",
        name: "payments",
        component: payments_settings,
        meta: {
          title: "payments",
          permission: "settings payments",
        },
      },
      {
        path: "branches",
        name: "branches",
        component: branches,
        meta: {
          title: "branches",
          permission: "settings branches access",
        },
      },
      {
        path: "branch/:id?",
        name: "branch",
        component: branch,
        meta: {
          title: "branch",
          permission: "settings branches edit",
        },
      },

      {
        path: "advanced",
        name: "advanced",
        component: advanced_settings,
        meta: {
          title: "advanced",
        },
      },

      {
        path: "cashers",
        name: "cashers",
        component: cashers,
        meta: {
          title: "cashers",
          permission: "settings casher access",
        },
      },
      {
        path: "style",
        name: "storeStyle",
        component: storeStyle,
        meta: {
          title: "storeStyle",
          permission: "settings style access",
        },
      },
      {
        path: "mobile",
        name: "mboileAppSettings",
        component: mboileAppSettings,
        meta: {
          title: "mboileAppSettings",
          permission: "settings mobile access",
        },
      },
    ],
  },

  //--------------- Sections

  // {
  //   path: "/brands",
  //   name: "brands",
  //   component: brands,
  //   meta: {
  //     title: "brands",
  //     requiresAuth: true,
  //     super_admin: false,
  //   },
  // },
  //--------------- Products

  {
    path: "/products",
    component: products,
    meta: {
      requiresAuth: true,
      super_admin: false,
      permission: "products access",
    },
    children: [
      {
        path: "/",
        name: "products",
        component: productsTable,
        meta: {
          title: "products",
          requiresAuth: true,
          super_admin: false,
          permission: "products access",
        },
      },
      {
        path: "product/:id?",
        name: "product",
        component: product,
        meta: {
          title: "product",
          requiresAuth: true,
          super_admin: false,
          permission: "products access",
        },
      },
    ],
  },

  //--------------- public
  {
    path: "/",
    name: "login",
    component: login,
    meta: {
      title: "login",
      requiresAuth: false,
    },
  },

  {
    path: "*",
    name: "problem",
    component: notfound,
    meta: {
      title: "notfound",
      requiresAuth: false,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach(async (to, from, next) => {
  // تحديث العنوان
  document.title = to.meta.title;

  // التحقق من حالة اللغات
  if (!store.state.langs.done && store.getters.loggedin) {
    await store.dispatch("get_langs");
  }

  const isStore = store.state.restline.is_store;
  const isLoggedIn = store.getters.loggedin;
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const isSuperAdminRoute = to.meta.super_admin;
  const userPermissions = store.state.userPermissions;

  // دالة مساعدة للتحقق من الأذونات
  const hasPermission = (permission) => userPermissions.includes(permission);

  // معالجة التوجيهات للصفحة غير موجودة
  if (to.name === "notfound") {
    return next();
  }

  // معالجة التوجيهات للمسارات التي لا تتطلب مصادقة
  if (!requiresAuth) {
    if (to.name === "login") {
      console.log(!isStore && isLoggedIn);
      if (isStore && isLoggedIn) {
        return next("products");
      } else if (!isStore && isLoggedIn) {
        return next("stores");
      }
    }
    return next();
  }


  if (!isLoggedIn) {
    return next({ name: "login" });
  }

  if (!isStore) {
    if (isSuperAdminRoute) {
      return next();
    }
    return next("stores");
  }

  if (!isSuperAdminRoute) {
    if (hasPermission(to.meta.permission)) {
      return next();
    }
    return next({ name: "problem" });
  }


  if (to.name === "login") {
    return next(isStore ? "products" : "stores");
  }

  next();
});

export default router;
