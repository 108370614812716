import Vue from "vue";
import App from "./App.vue";
import axios from "axios";
import VueAxios from "vue-axios";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import Vuetify from "vuetify";
import Global from "./mixins/Global";

Vue.use(VueAxios, axios, Vuetify);
Vue.mixin(Global);
import VueHtmlToPaper from "vue-html-to-paper";

const options = {
  name: "_blank",
  specs: [
    "fullscreen=400",
    "titlebar=yes",
    "scrollbars=no",
    "width=600",
    "height=600",
  ],
  styles: ["https://app.menyo.co/print_style.css"],
};

Vue.use(VueHtmlToPaper, options);

axios.defaults.baseURL = "https://app.rsst.app/api/v2/";
//axios.defaults.baseURL = "http://127.0.0.1:8000/api/v2/";
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
