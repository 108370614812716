

export default {
  name: "Global",
  data() {
    return {};
  },
  methods: {
    trans(page, name) {
      if (this.$store.state.restline.is_store) {
        return this.$store.getters.TransName({
          page: page,
          name: name,
        });
      }
      return "";
    },
    checkPermission(permission) {
      let state = this.$store.state;
      return state.userPermissions.includes(permission);
    },
  },

};
