<template>
  <v-navigation-drawer
    class="menu rounded"
    floating
    v-model="$store.state.drawer"
    right
    :mini-variant.sync="$store.state.mini"
    :app="$store.state.break_point <= 991"
    :style="{
      height:
        $store.state.break_point <= 991 ? '100%!important' : 'auto!important',
    }"
  >
    <v-list-item class="px-2">
      <v-list-item-avatar>
        <v-img src="https://randomuser.me/api/portraits/men/85.jpg"></v-img>
      </v-list-item-avatar>
      <v-list-item-title
        >{{
          $store.state.restline ? $store.state.restline.name : "Super Admin"
        }}
      </v-list-item-title>

      <v-btn
        color="grey lighten-3"
        fab
        x-small
        depressed
        v-if="$store.state.break_point > 991"
        @click.stop="$store.state.mini = !$store.state.mini"
      >
        <v-icon>chevron_right</v-icon>
      </v-btn>
    </v-list-item>
    <v-divider></v-divider>

    <div
      v-if="
        $store.state.translations.data.length != 0 ||
        !$store.state.restline.is_store
      "
    >
      <v-list dense>
        <!-- <v-list-item-title
          v-if="!$store.state.mini"
          class="grey--text lighten-3 caption px-4"
          >{{ list.title }}</v-list-item-title
        > -->
        <v-list-item
          active-class="primary white--text"
          v-for="(item, x) in links"
          :key="x"
          link
          :to="{ name: item.link }"
        >
          <v-list-item-icon class="me-2">
            <v-icon style="font-size: 18px">{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title class="font-weight-bold">{{
              item.title
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item active-class="primary white--text" @click="logout()">
          <v-list-item-icon class="me-2">
            <v-icon style="font-size: 18px">logout</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title class="font-weight-bold">
              {{ trans("menu", "menu_logout") }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- <v-divider v-if="x != links.length - 1"></v-divider> -->
      </v-list>
    </div>

    <div v-else>
      <v-skeleton-loader
        v-for="i in 11"
        :key="i"
        type="table-heading"
      ></v-skeleton-loader>
    </div>
  </v-navigation-drawer>
</template>
<script>
export default {
  data() {
    return {
      drawer: true,

      mini: false,
    };
  },
  computed: {
    all_links() {
      return [
        {
          title: "",
          super: false,
          list: [
            {
              title: this.trans("menu", "menu_home"),
              icon: "home",
              link: "dashboard",
              permission: "more dashboard",
              super: false,
            },
            {
              title: this.trans("menu", "menu_orders"),
              icon: "inventory_2",
              link: "orders",
              permission: "orders access",
            },
            {
              title: this.trans("menu", "menu_orders"),
              icon: "description",
              link: "pointsReport",
              permission: "orders access",
            },
            {
              title: this.trans("menu", "menu_products"),
              icon: "inventory_2",
              link: "products",
              permission: "products access",
            },
            {
              title: this.trans("menu", "menu_categories"),
              icon: "category",
              link: "categories",
              permission: "categories access",
            },
            {
              title: this.trans("menu", "menu_notes"),
              icon: "category",
              link: "notes",
              permission: "notes access",
            },
            {
              title: this.trans("menu", "menu_additions"),
              icon: "category",
              link: "additions",
              permission: "additions access",
            },
            {
              title: this.trans("menu", "menu_employees"),
              icon: "category",
              link: "employees",
              permission: "employees access",
            },
            {
              title: this.trans("menu", "menu_places"),
              icon: "category",
              link: "locations",
              permission: "locations access",
            },
            {
              title: this.trans("menu", "menu_pages"),
              icon: "category",
              link: "intro_pages",
              permission: "intro_pages access",
            },
            {
              title: this.trans("menu", "menu_groups"),
              icon: "category",
              link: "groups",
              permission: "groups access",
            },
            {
              title: this.trans("menu", "menu_banners"),
              icon: "category",
              link: "banners",
              permission: "banners access",
            },
            {
              title: this.trans("menu", "menu_deliveries"),
              icon: "category",
              link: "drivers",
              permission: "deliveries access",
            },

            {
              title: this.trans("menu", "menu_customers"),
              icon: "category",
              link: "customers",
              permission: "customers access",
            },
            {
              title: this.trans("menu", "menu_rates"),
              icon: "star_rate",
              link: "rates",
              super: false,
              permission: "more rates",
            },
            {
              title: this.trans("menu", "menu_messages"),
              icon: "category",
              link: "messages",
              permission: "more notify topic",
            },
            {
              title: this.trans("menu", "menu_settings"),
              icon: "settings",
              link: "settings",
              permission: "settings access",
            },
          ],
        },
        {
          title: "",
          super: true,
          list: [
            {
              title: "المحلات",
              icon: "corporate_fare",
              link: "stores",
            },
            {
              title: "الدليفري",
              icon: "category",
              link: "deliveries",
            },
            {
              title: "الترجمة",
              icon: "category",
              link: "translations",
            },
            {
              title: "البكدجات",
              icon: "category",
              link: "packages",
            },
            {
              title: "اختيارات التنسيق",
              icon: "format_color_fill",
              link: "styleOptions",
            },
            {
              title: "اللغات",
              icon: "language",
              link: "languages",
            },
          ],
        },
      ];
    },
    links() {
      let links = [];
      this.all_links.forEach((e) => {
        if (e.super == !this.$store.state.restline.is_store) {
          if (!e.super) {
            links = e.list.filter((f) => this.checkPermission(f.permission));
          } else {
            links = e.list;
          }
        }
      });
      return links;
    },
  },
  methods: {
    logout() {
      let vm = this;
      this.$store.commit("logout");
      setTimeout(() => {
        vm.$router.push({ name: "login" });
      });
    },
  },
};
</script>

<style lang="scss">
.menu {
  // min-width: 245px;
  height: calc(100vh - 150px) !important;
  min-height: calc(100vh - 150px) !important;
  @media (max-width: 991px) {
    height: 100vh !important;
    min-height: 100vh !important;
  }
  max-height: calc(100vh - 200px) !important;
  box-shadow: 0px 3px 1px -5px rgb(0 0 0 / 12%), 0px 2px 4px 0px rgb(0 0 0 / 0%),
    0px 1px 10px 0px rgb(0 0 0 / 12%);
}
</style>
